import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/users/index.vue')
    },
    {
      path: '/orders',
      name: 'orders',
      component: () => import('@/views/orders/index.vue')
    },
    {
      path: '/archive',
      name: 'archive',
      component: () => import('@/views/messages/archive.vue')
    },
    {
      path: '/devices',
      name: 'devices',
      component: () => import('@/views/devices/index.vue')
    },
    {
      path: '/resellers/list',
      name: 'resellers/list',
      component: () => import('@/views/resellers/list.vue')
    },
    {
      path: '/resellers/orders',
      name: 'resellers/orders',
      component: () => import('@/views/resellers/orders.vue')
    },
    {
      path: '/allowed',
      name: 'allowed',
      component: () => import('@/views/allowed.vue')
    },
    {
      path: '/marketing/stats',
      name: 'marketing-stats',
      component: () => import('@/views/marketing/stats.vue')
    },
    {
      path: '/marketing/create',
      name: 'marketing-new',
      component: () => import('@/views/marketing/create.vue')
    },
    {
      path: '/marketing/list',
      name: 'marketing-list',
      component: () => import('@/views/marketing/list.vue')
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/auth/logout.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      name: 'error-4042',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
